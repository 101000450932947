@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Suisse';
  src: url('../public/fonts/Suisse/SuisseRegular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseBook';
  src: url('../public/fonts/Suisse/SuisseBook.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseBold';
  src: url('../public/fonts/Suisse/SuisseBold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseSemibold';
  src: url('../public/fonts/Suisse/SuisseSemibold.otf');
  font-display: swap;
}

@layer base {
  h1 {
    @apply font-semibold text-m-h1 lg:text-h1;
  }
  h2 {
    @apply font-semibold text-m-h2 lg:text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply font-bold text-m-h4 lg:text-h4;
  }
  h5 {
    @apply font-semibold text-m-h5 lg:text-h5;
  }
  p {
    @apply text-base;
  }
  input {
    @apply bg-[right_8px_center] bg-no-repeat;
    &:disabled {
      @apply border-grey-lightest bg-grey-wash text-ink-light;
    }
    &.input-error {
      @apply bg-inputError;
    }
    &.input-success {
      @apply bg-inputValid;
    }
  }
  .error {
    @apply float-left text-input-error text-red;
  }
  .paragraph-1 {
    @apply text-m-lead lg:text-lead;
  }
  .paragraph-2 {
    @apply text-m-standard lg:text-long-form;
  }
  .paragraph-3 {
    @apply text-m-standard lg:text-standard;
  }
  .paragraph-4 {
    @apply text-m-small-body lg:text-small-body;
  }
  a {
    @apply font-medium text-blue no-underline hover:text-indigo hover:underline;
  }
  .desktop-images {
    @apply col-start-1 row-start-1 w-full;
  }
}

@layer components {
  .btn {
    @apply mt-12 h-16 w-full rounded-l-full rounded-r-full bg-blue font-bold text-white;
    &:disabled {
      @apply bg-grey;
    }
    &:enabled:hover {
      @apply bg-indigo;
    }
    &:focus {
      @apply border-[3px] border-solid border-blue-light;
    }
    &:active {
      @apply scale-95;
    }
  }
  .btn-outline {
    @apply mt-12 h-16 w-full rounded-l-full rounded-r-full border-[3px] border-blue bg-transparent font-bold text-blue;
    &:disabled {
      @apply border-grey-lightest text-grey-lightest;
    }
    &:enabled:hover {
      @apply bg-blue text-white;
    }
    &:active {
      @apply scale-95;
    }
  }
  .banner {
    @apply m-auto mt-larger flex h-[108px] w-full max-w-[426px] items-center rounded-md py-4 pl-6 text-grey drop-shadow-md;
  }
  .progress-container {
    @apply rounded-lg bg-white;
    .progress-line {
      @apply h-2 rounded-2xl bg-blue delay-300 duration-1000 ease-in;
    }
  }
  .bottomSheet {
    @apply translate-y-full bg-white shadow-modal ease-in;
    &.active {
      @apply translate-y-0 rounded-t-3xl font-sans duration-700 ease-in;
    }
  }
  .modalContainer {
    @apply rounded bg-white shadow-modal;
  }
  .modal-area {
    @apply absolute inset-0 z-50 m-auto max-h-[247px] max-w-[640px] bg-transparent;
  }
  .verticalDivider {
    @apply mx-extra-large grid grid-flow-row grid-rows-[min-content_min-content_min-content] justify-items-center pt-even-larger pb-extra-large text-grey-light;
  }
  .horizontalDivider {
    @apply grid grid-flow-col grid-cols-[1fr_min-content_1fr] text-grey-light lg:pt-even-larger lg:pb-extra-large;
  }
  .overlay {
    @apply absolute z-10 flex h-screen w-screen overscroll-none bg-grey opacity-80;
  }
}

@layer utilities {
  .active {
    @apply font-semibold text-blue;
  }
  .inactive {
    @apply text-grey;
  }
  .success {
    @apply font-semibold text-green;
  }
  .form-max-width {
    @apply mx-auto max-w-screen-formContainer lg:w-formContainer;
  }
}

/**
 * Added to make the bottom chrome on ios safari visible all the time because
 * of the "dead zone" that safari adds.
 *
 * https://www.eventbrite.com/engineering/mobile-safari-why/
 */
html,
body {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
